import xhr from './xhr/';
/**
 * 促进服务管理相关的 API
 */
class BoostService {
	// 促进服务管理首页 获取后台传过来的select配置项
	getBoostSelect(data) {
		return xhr({
			method: 'get',
			url: '/boost/index',
			params: data,
		});
	}

	// 搜索
	getBoostList(data) {
		return xhr({
			method: 'get',
			url: '/boost/list',
			params: data,
		});
	}

	// 删除问题或记录
	deleteBoost(data) {
		return xhr({
			method: 'post',
			url: '/boost/delete',
			params: data,
		});
	}

	// 分配问题负责人
	distribute(data) {
		return xhr({
			method: 'post',
			url: '/boost/distribution',
			params: data,
		});
	}

	// 分配问题负责人
	distributeAll(data) {
		return xhr({
			method: 'post',
			url: '/boost/all_distribution',
			params: data,
		});
	}

	// 获取某人所有的促进记录
	getRecordList(data) {
		return xhr({
			method: 'get',
			url: '/boost/list_member',
			params: data,
		});
	}

	// 获取某人某个促进记录详情
	getRecordDetail(data) {
		return xhr({
			method: 'get',
			url: '/boost/detail',
			params: data,
		});
	}

	// 开始促进
	getStart(data) {
		return xhr({
			method: 'get',
			url: '/boost/done_page',
			params: data,
		});
	}

	// 促进备注
	boostMark(data) {
		return xhr({
			method: 'post',
			url: '/common/add_remark',
			params: data,
		});
	}

	// 完成促进
	boostDone(data) {
		return xhr({
			method: 'post',
			url: '/boost/done',
			params: data,
		});
	}

	// 预警统计
	alarmStatistics(data) {
		return xhr({
			method: 'post',
			url: '/cj/sjtj/yjtj',
			params: data,
		});
	}

	// 问题统计
	problemStatistics(data) {
		return xhr({
			method: 'post',
			url: '/cj/sjtj/wt',
			params: data,
		});
	}

	// 数据统计
	dataStatistics(data) {
		return xhr({
			method: 'post',
			url: '/cj/sjtj/sjwsc',
			params: data,
		});
	}

	// 待分配统计
	undoStatistics(data) {
		return xhr({
			method: 'post',
			url: '/cj/cj/dfptj',
			params: data,
		});
	}

	// 待分配列表
	undoList(data) {
		return xhr({
			method: 'post',
			url: '/cj/cj/dfplb',
			params: data,
		});
	}

	// 确定任务
	confirmTask(data) {
		return xhr({
			method: 'post',
			url: '/cj/cj/qdrw',
			params: data,
		});
	}

	// 今日任务
	todayTask(data) {
		return xhr({
			method: 'post',
			url: '/cj/cj/jrrw',
			params: data,
		});
	}

	// 基本信息
	screenProfile(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/jbxx',
			params: data,
		});
	}

	// 促进记录
	screenPromotion(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/cjjl',
			params: data,
		});
	}

	// 方案记录
	screenPlan(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/fajl',
			params: data,
		});
	}

	// 咨询记录
	screenAsk(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/zxjl',
			params: data,
		});
	}

	// 预警记录
	screenAlarm(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/yjjl',
			params: data,
		});
	}

	// 行为记录
	screenBehavior(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/xwjl',
			params: data,
		});
	}

	// 服务记录
	screenService(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/fwjl',
			params: data,
		});
	}

	// 问题分类
	problemTypes(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/wtfl',
			params: data,
		});
	}

	// 问题上报
	problemSubmit(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/wtsb',
			params: data,
		});
	}

	// 今日待促进任务
	todoTask(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/jrdcj',
			params: data,
		});
	}

	// 促进录入
	promotionSubmit(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/cjlr',
			params: data,
		});
	}

	// 下次促进时间
	promotionTime(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/cjpz',
			params: data,
		});
	}

	// 促进记录
	promotionRecords(data) {
		return xhr({
			method: 'post',
			url: '/cj/menu/cjjl',
			params: data,
		});
	}

  	// 促进记录-删除
  	promotionDelete(data) {
		return xhr({
	  		method: 'post',
	  		url: '/cj/menu/del',
	  		params: data,
		});
  	}

  	// 促进师
  	promotionStaffs(data) {
		return xhr({
	  		method: 'post',
	  		url: '/cj/menu/cjs',
	  		params: data,
		});
  	}

	// 卡系类型
	cardTypes(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/kxlb',
			params: data,
		});
	}

	// 大界面 - 复健新增
	screenFj(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/fjadd',
			params: data,
		});
	}

	// 大界面 - 训练新增
	screenXl(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/xladd',
			params: data,
		});
	}

	// 大界面 - 主诉新增
	screenZs(data) {
		return xhr({
			method: 'post',
			url: '/main/add',
			params: data,
		});
	}

	// 大界面 - 备注
	screenRemark(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/bzxg',
			params: data,
		});
	}

	// 大界面 - 复健列表
	screenFlList(data) {
		return xhr({
			method: 'post',
			url: '/cj/djm/fl',
			params: data,
		});
	}
}

// 实例化后导出，全局单例
export default new BoostService();
